.login-parent {
    background-color: var(--dark-bg);
    min-height: 100vh;
    width: 100%;
    // padding: 30px;
    color: white;
    max-height: 100vh;
    display: flex;

    a {
        text-decoration: none;
    }

    .left,
    .right {
        flex: 1;
        // padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .left {
        // flex: 1.5;

        .illustration {
            height: 100%;
            width: 100%;
            border-radius: 5px;
            background-image: url(../assets/galery/design2.jpg);
            background-size: cover;
            background-position: right top;

        }

        .il-inner {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image:
                // //linear-gradient(to bottom, #35db07af, #035314);
                linear-gradient(to bottom, rgb(13 42 5 / 0%), #000000d3);
        }

        .logo2 {
            height: 150px;
            width: 150px;
            background-image: url(../assets/logo/white-origingal.svg);
            background-size: cover;
            background-position: right;
            margin-left: var(--padding-left);
            cursor: pointer;
            filter: drop-shadow(3px 0 0 var(--dark-bg)) drop-shadow(0 3px 0 var(--dark-bg)) drop-shadow(-3px 0 0 var(--dark-bg)) drop-shadow(0 -3px 0 var(--dark-bg));
        }

        .logo {
            height: 250px;
            width: 500px;
            background-image: url(../assets/logo/words.svg);
            background-size: cover;
            background-position: right;
            margin-left: var(--padding-left);
            cursor: pointer;
            filter: drop-shadow(3px 0 0 var(--dark-bg)) drop-shadow(0 3px 0 var(--dark-bg)) drop-shadow(-3px 0 0 var(--dark-bg)) drop-shadow(0 -3px 0 var(--dark-bg));
        }
    }

    .right {
        position: relative;

        .heading {
            font-family: MontBamboo;
            font-size: 70px;
            color: var(--green-bg);
            line-height: 50px;
            margin-bottom: 10px;
            font-weight: 700;
            text-align: center;
        }

        p {
            margin-bottom: 20px;
            text-align: center;

            a {
                color: var(--green-bg);
            }
        }

        .inner {
            width: 400px;

            .large-but {
                padding: 22px 10px;
                margin-top: 30px;
            }
        }

        .loading-anim {
            position: absolute;
            background: rgba(0, 0, 0, 0.675);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .remember {
            margin-top: 20px;

            input {
                background-color: transparent;
            }
        }
    }
}