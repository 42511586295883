.admin-parent {
    background-color: #1f1f1f;
    min-height: 100vh;
    width: 100%;
    // padding: 30px;
    color: white;
    max-height: 100vh;
    display: flex;
    overflow: hidden;

    .left {
        width: 190px;
        height: 100vh;
        background-color: var(--dark-bg);
        ;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;

        // .logo {
        //     height: 50px;
        //     width: 50px;
        //     background-image: url(../assets/logo/white-origingal.svg);
        //     background-size: cover;
        //     background-position: right;
        //     cursor: pointer;
        //     margin-top: 30px;
        // }
        .admin-title {
            background-color: #e3ffa8;
            height: 40px;
            width: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            margin-top: 50px;
            font-size: 12px;
            letter-spacing: 3px;
            font-family: MontBamboo;
            border-radius: 3px;
            color: #1f1f1f;
        }

        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 30px;
            width: 100%;
            padding: 0;
            margin: 0;
            padding-top: 30px;
            margin-top: 30px;

            // border-top: 1px solid #3e3e3e;
            a {
                text-decoration: none;
                color: #a5a4a4;
                font-weight: 600;
                font-size: 14px;
                cursor: pointer;
                transition: .5s;
                display: flex;
                align-items: center;
                column-gap: 10px;

            }

            li {
                width: 60%;



                // justify-content: center;
                svg {
                    font-size: 22px;
                }

            }

            a:hover {
                color: white;
            }

            .active a {
                color: #fff !important;
            }

            .logout-bt {
                // margin-top: 40vh;
                position: absolute;
                bottom: 50px;
                background-color: #2b2b2b;
                padding: 10px;
                padding-right: 20px;
                border-radius: 20px;
                justify-content: center;
                display: flex;
            }
        }
    }

    .right {
        flex: 1;

        position: relative;

        .banner {
            position: absolute;
            top: 0;
            left: 0;
            height: 30%;
            width: 100%;
            background-image: url(../assets/galery/2.JPG);
            background-size: cover;
            background-position: center top;
        }

        .banner::after {
            content: "";
            height: 100%;
            width: 100%;
            background-image: linear-gradient(to bottom, #1f1f1f62, #1f1f1f);
            position: absolute;
        }

        .right-inner {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

        }

        .dashboard-parent {
            padding: 50px;
            padding-top: 10px;

            h1 {
                font-weight: 400;
                font-family: RoundBamboo;
                font-size: 30px;
                margin-top: 30px;
                filter: drop-shadow(1px 0 0 #1f1f1f) drop-shadow(0 1px 0 #1f1f1f) drop-shadow(-1px 0 0 #1f1f1f) drop-shadow(0 -1px 0 #1f1f1f);
                margin-bottom: 50px;
            }

            h3 {
                color: #a5a4a4;
            }

            .stats-parent {
                display: flex;
                column-gap: 20px;
                margin-bottom: 40px;
                width: 91%;

                .stats-item {
                    border-radius: 20px;
                    height: 180px;
                    color: #1f1f1f;
                    // width: 300px;
                    flex: 1;

                    .title {
                        margin: 20px;
                        margin-bottom: 0px;
                        margin-top: 25px;
                        font-size: 12px;
                        // color: #1f1f1f;
                        font-weight: 600;
                    }

                    .value {
                        margin: 20px;
                        margin-top: 0px;

                        // margin-left: 15px;
                        // color: #1f1f1f;
                        font-weight: 700;
                        font-size: 35px;
                        font-family: Popbamboo;
                    }

                    .footer {

                        margin: 20px;
                        margin-bottom: 0px;
                        margin-top: 48px;
                        font-size: 13px;
                        // color: #1f1f1f;
                        font-weight: 400;
                        // text-align: justify;
                    }
                }

            }

            .stats-item:nth-child(1) {
                background-color: #e3ffa8
            }

            .stats-item:nth-child(2) {
                background-color: #45ffbc; //var(--green-bg);
                // color: white !important;
            }

            .stats-item:nth-child(3) {
                background-color: #bdbbb7
            }

            .stats-item:nth-child(4) {
                background-color: #fffea8
            }

            .bookings-parent {
                // display: flex;
                // column-gap: 20px;
                // margin-bottom: 40px;
                width: 91%;

                .rdt_TableHeadRow,
                .rdt_TableRow,
                .rdt_Pagination {
                    background-color: var(--dark-bg);
                    color: white;

                    svg {
                        color: white !important;
                    }
                }

                .rdt_Pagination {
                    svg {
                        color: white !important;
                        filter: invert(1);
                    }
                }

                .contact {
                    display: flex;
                    column-gap: 20px;

                    a {
                        color: white;
                    }

                    svg {
                        font-size: 20px;
                        cursor: pointer;
                    }

                    svg:hover {
                        color: #45ffbc;
                    }
                }
            }
        }
    }



    .galery-parent {
        background-color: #1f1f1f;
        height: 100vh;


        .drop-region {
            height: 300px;
            // background-color: #45ffbc;
            padding: 30px;
            cursor: pointer;

            .drop-inner {
                width: 100%;
                height: 100%;
                border: 3px dashed #696a6b;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #696a6b;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .pictures-parent {
            display: grid;
            grid-template-columns: auto auto auto auto auto;
            padding: 30px;
            overflow-y: scroll;
            height: 300px;

            .pictures-item {
                height: 200px;
                background-color: #3b3c3c;
                border-radius: 10px;
                border: 2px solid #201f1f;
                background-size: cover;
                background-position: center;
                display: flex;
                justify-content: right;
                align-items: end;

                .delete-button {
                    font-size: 20px;
                    height: 50px;
                    width: 50px;
                    background-color: #1f1f1f;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    margin: 10px;
                    color: #a5a4a4;
                    cursor: pointer;
                    transition: .5s;
                }

                .delete-button:hover {
                    background-color: var(--green-bg);
                    color: white;
                }
            }
        }

        .loading-animation {
            background-color: #1f1f1fb8;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            div {
                position: absolute;
                top: 30%;
                left: calc(50% - 75px);
            }
        }
    }

    .messaging-parent {
        background-color: #1f1f1f;
        height: 100vh;
        display: flex;

        .m-left {
            flex: 1;
            // display: flex;

            // flex-direction: ;
            // justify-content: center;
            .title {
                display: flex;
                padding: 30px;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #2b2b2b;

                div:first-child {
                    flex: 1;
                    font-weight: 700;
                    font-size: 30px;
                }

                div:last-child {
                    color: var(--green-bg);
                    font-size: 20px;
                    padding-top: 5px;
                }
            }

        }

        .m-right {
            flex: 2;
            background-color: #373737;

            .empty-state {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                color: #a5a4a4;

                .illustration {
                    height: 200px;
                    width: 250px;
                    background-image: url(../assets/illustrations/messaging.svg);
                    background-size: cover;
                    background-position: center top;
                }

                h2 {
                    margin: 0;
                    margin-bottom: 10px;
                    margin-top: 10px;
                }

                p {
                    margin: 0;
                    width: 250px;
                    text-align: center;
                    font-size: 13px;
                }

            }
        }
    }

}