.booking-header-link {
    // padding: 15px 10px;
    margin-right: 60px;
    margin-top: 5px;
    margin-left: -40px;
    font-weight: 700;
    color: #c1c0c0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    border: 1px solid #434343;
    max-height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    transition: .5s;
    cursor: pointer;
}

.booking-header-link:hover {
    background-color: #474747;
}

.booking-parent {
    background-color: var(--dark-bg);
    min-height: 100vh;
    width: 100%;
    // padding: 30px;
    color: white;
    max-height: 100vh;

    // overflow-y: scroll;
    // scroll-snap-type: mandatory;
    // scroll-snap-points-y: repeat(3rem);
    // scroll-snap-type: y mandatory;
    .loading-anim {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding-top: 30vh;

        h1 {
            margin-top: 20px;
            font-weight: 500;
            font-size: 25px;
            margin-bottom: 0;
        }

        p {
            color: #ddd;

            margin: 0;

        }

        .sub {
            color: #777;
            margin-top: 10px;
            font-size: 13px;
        }
    }


    .logo {
        height: 50px;
        width: 50px;
        background-image: url(../assets/logo/white-origingal.svg);
        background-size: cover;
        background-position: right;
        margin-left: var(--padding-left);
        cursor: pointer;
    }

    .mid-logo {
        height: 80px;
        width: 150px;
        background-image: url(../assets/logo/words.svg);
        background-size: cover;
        background-position: right;
        margin-top: -30px;
        margin-left: -70px;
    }

    .booking-inner {
        padding-top: 120px;
        display: flex;
        // padding-left: 150px;
        max-width: 1300px;
        margin: auto;


        .left {
            flex: 2;
            min-height: 70vh;
            background-color: var(--dark-light-bg);
            // padding:40px;
            padding-top: 60px;

            padding-bottom: 0;
            border-radius: 20px;
            max-width: 700px;

            h1 {
                margin: 0;
                font-family: MontBamboo;
                font-size: 30px;
                margin-bottom: 20px;
                // letter-spacing: 2px;
                // text-transform: uppercase;
            }

            h3 {
                margin-top: 40px;
            }

            p {
                margin-top: 80px;
                font-size: 13px;

                text-align: center;

                a {
                    color: var(--green-bg);
                }
            }

            .statement {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 50px;
                background-color: var(--dark-bg);
                margin-left: 30px;
                margin-right: 30px;
                padding: 30px 30px;
                border-radius: 20px;

                svg {
                    color: var(--green-bg);
                    font-size: 30px;
                }

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 10px;
                }

                ul {
                    display: flex;
                    list-style: none;
                    column-gap: 10px;
                    margin-top: 30px;

                    li {
                        display: flex;
                        column-gap: 10px;
                        font-size: 14px;
                        flex: 1;

                        svg {}
                    }
                }
            }

        }

        .row {
            display: flex;
            column-gap: 15px;
            // width: 550px;
            margin: auto;
            padding: 0 40px;
            // justify-content: center;
            align-items: center;
        }

        .right {
            flex: 1;
            padding: 0px 70px;
            padding-right: 0px;

            .right-inner {
                border: 1px solid #222;
                border-radius: 20px;
                padding: 40px 10px;
            }

            .title {
                flex: 1;
                font-size: 12px;
                display: flex;
                // justify-content: center;
                align-items: center;
                column-gap: 10px;
                color: #a9a7a7;

                svg {
                    font-size: 23px;
                    color: #bbb;
                }
            }

            .value {
                color: white;
                font-weight: 600;
                font-size: 14px;
            }

            .highlighted {
                font-size: 28px;
                font-weight: 700;
                color: var(--green-bg);
            }

            .row {
                margin-bottom: 7px;
            }

            .agreement {
                font-size: 12px;
                max-width: 400px;
                margin: auto;
                text-align: center;
                color: #6b6a6a;
                font-weight: 500;
                margin-top: 30px;

                a {
                    color: var(--green-bg);
                }
            }
        }
    }

    .mpesa-parent {
        display: flex;
        min-height: 100vh;
        overflow: hidden;

        .left,
        .right {
            flex: 1
        }

        .left {
            padding-left: var(--padding-left);

            .phone-anim {
                z-index: 100;
                position: relative;
                // background-color: #bbb;
                height: 100%;
                width: 100%;

                h1 {
                    font-family: RoundBamboo;
                    position: absolute;
                    bottom: -50px;
                    left: 20%;
                    font-size: 30px;
                    line-height: 40px;
                    background-color: var(--dark-bg);
                    padding: 30px 100px;
                    text-align: center;
                    width: 200px;
                    margin: auto;
                    display: none;
                }

                .animation {
                    // display: none;
                    position: absolute;
                    height: 900px;
                    width: 900px;
                    bottom: -17px;
                    left: -80px;
                    // margin-left: -21%;
                    // margin-bottom: -40px;
                }
            }

            .thumbsup-anim {
                z-index: 100;
                position: relative;
                // background-color: #bbb;
                height: 100%;
                width: 100%;

                h1 {
                    font-family: RoundBamboo;
                    position: absolute;
                    bottom: -50px;
                    left: 20%;
                    font-size: 30px;
                    line-height: 40px;
                    background-color: var(--dark-bg);
                    padding: 30px 100px;
                    text-align: center;
                    width: 200px;
                    margin: auto;
                    display: none;
                }

                .animation {
                    // display: none;
                    position: absolute;
                    height: 600px;
                    width: 600px;
                    bottom: 10%;
                    left: 5%;
                    // margin-left: -21%;
                    // margin-bottom: -40px;
                }
            }
        }

        .right {
            flex: .9;
            padding: var(--padding-left);
            padding-top: 120px;
            padding-right: 170px;

            .right-inner {
                background-color: var(--dark-light-bg);
                //  border: 1px solid #3a3939;
                border-radius: 20px;
                height: 100%;
                width: 100%;
                padding-top: 0px;

                h1 {
                    font-size: 30px;
                    line-height: 30px;
                    margin: 0;
                    padding: 60px;
                    padding-top: 90px;
                    padding-bottom: 20px;
                }

                p {
                    padding: 0 60px;
                    font-size: 16px;
                    text-align: justify;
                }

                .row {
                    display: flex;
                    column-gap: 15px;
                    // width: 550px;
                    margin: auto;
                    padding: 0 60px;
                    // justify-content: center;
                    align-items: center;

                    .title {
                        flex: 1;
                        font-size: 13px;
                        font-weight: 600;
                        color: #ffffff;
                    }

                    .value {
                        font-weight: 700;
                    }
                }

                // .mpesa-logo{
                //     height: 90px;
                //     width: 90px;
                //     margin-bottom: -50px;
                //     background-image: url(../assets/mpesa.png);
                //     background-size: cover;
                //     margin-left: 60px;
                // }
            }

            // h1{
            //     line-height: 30px;
            //     margin-top: 20px;
            // }
        }


    }

    .success-parent {
        display: flex;
        min-height: 100vh;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        .success-inner {
            background-color: var(--dark-light-bg);
            border-radius: 20px;
            height: 100%;
            width: 400px;
            margin-top: 20px;
            padding: 0 70px;
            padding-bottom: 40px;
            text-align: center;
            p{
                text-align: justify;
            }
        }
    }
}

@media only screen and (max-width : 992px) {
    .booking-parent .booking-inner .left{
        border-radius: 0;
        padding-bottom: 20px;
    }
    .booking-parent .booking-inner{
        display: block;
    }
    .booking-parent .booking-inner .row{
        display: block;
    }
    .booking-parent .booking-inner{
        padding-top: 0;
    }
    .booking-parent .booking-inner .left .statement{
        display: none;
    }
    .booking-parent .booking-inner .right{
        background-color: var(--dark-bg);
        padding: 0;
    }
    .booking-parent .booking-inner .right .right-inner{
        padding: 0;
    }
    .booking-parent .booking-inner .right .agreement{
        padding: 20px;
    }
    .booking-parent .mpesa-parent .left{
        display: none;
    }
    .booking-parent .mpesa-parent .right{
        padding: 0;
        flex: 1;
    }
    .booking-parent .mpesa-parent .right .right-inner p{
        padding: 0 20px;
        text-align: left;
    }
    .booking-parent .mpesa-parent .right .right-inner h1{
        padding-left: 20px;
        padding-right: 20px;
    }
    .booking-parent .mpesa-parent .right .right-inner .row{
        padding: 0 20px;
    }
    .booking-parent .mpesa-parent .right .right-inner .row .value{
        color: rgb(4, 208, 4);
    }
}
