.component {
    flex: 1;
    width: 100%;
    display: flex;

    .text-input-outlined {
        position: relative;
        border: 1px solid #4c4c4c;
        border-radius: 10px;
        margin-top: 40px;
        flex: 1;

        .title {
            position: absolute;
            top: -7px;
            left: 20px;
            background-color: var(--dark-light-bg);
            padding: 0 10px;
            font-weight: 700;
            font-size: 12px;
            // color: var(--green-bg);
        }

        input {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 20px;
            font-size: 16px;
            color: #fff;
            width: 100%;
        }
        textarea {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 20px;
            font-size: 16px;
            color: #fff;
            width: 100%;
            height: 150px;
        }
        
    }
    .text-input-simple {
        position: relative;
        border: 1px solid #4c4c4c;
        border-radius: 5px;
        margin-top: 20px;
        flex: 1;


        input {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 15px;
            font-size: 16px;
            color: #fff;
            width: 100%;
        }
        textarea {
            font-family: MontBamboo;
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 15px;
            font-size: 16px;
            color: #fff;
            width: 95%;
            height: 150px;
        }
    }
    .text-input-grey {
        position: relative;
        // border: 1px solid #4c4c4c;
        background-color: #2a2929;
        border-radius: 5px;
        margin-top: 20px;
        flex: 1;


        input {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 15px;
            font-size: 16px;
            color: #fff;
            width: 100%;
        }
        textarea {
            font-family: MontBamboo;
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 15px;
            font-size: 16px;
            color: #fff;
            width: 95%;
            height: 150px;
        }
    }
    .large-but {
        background-color: var(--green-bg);
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        font-family: MontBamboo;
        font-weight: 700;
        letter-spacing: 2px;
        padding: 10px 10px;
        border-radius: 5px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        cursor: pointer;
        transition: .5s;
    }

    .white-but {
        
        background-color: white;
        color: var(--dark-bg);
    }
    .black-but {
        // border: 1px solid #fff;
        background-color:  var(--dark-bg);
        color: white;
    }
    .transparent-but {
        border: 1px solid #fff;
        background-color: transparent;
        color: white;
    }
    .large-but:hover{
        background-color: #444;
        color: white;
    }
}