.mobile-menu {
    background-color: #000000f7;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
        list-style: none;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        font-family: RoundBamboo;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 20px;
        }
    }

    .close-ic {
        color: white;
        position: absolute;
        top: 14px;
        right: 14px;
    }
}