// .dark-theme{
//     filter: invert(1);
//     .bg{
//         background-image: url(../assets/galery/12.JPG) !important;
//     }
//     .theme-persist {
//     filter: invert(1);
// }
// }
.mobile-only {
    display: none !important;
}

.landing-parent {
    background-color: var(--dark-bg);
    // min-height: 100vh;
    width: 100%;
    // padding: 30px;
    color: white;
    max-height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(3rem);
    scroll-snap-type: y mandatory;


    // 

    .snap-item {
        scroll-snap-align: start;
    }

    .but {
        width: 100px;
        border: 2px solid #fff;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        font-weight: 700;
        padding: 15px 25px;
        cursor: pointer;
        transition: .5s;
    }

    .but:hover {
        background-color: var(--green-bg);
        border: 2px solid var(--green-bg);
    }
}

.header {
    position: fixed;
    top: 0;
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
    // 
    display: flex;
    z-index: 1000;
    transition: .5s;

    .logo {
        height: 50px;
        width: 50px;
        background-image: url(../assets/logo/white-origingal.svg);
        background-size: cover;
        margin-left: var(--padding-left);
        cursor: pointer;
    }

    .mid {
        padding-right: 0px;
        padding-left: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        list-style: none;
        column-gap: 30px;
        font-weight: 700;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;

        li {
            padding-bottom: 10px;
            cursor: pointer;
        }

        li:hover {
            color: #ddd;
        }
    }

    .but {
        padding: 15px 10px;
        margin-right: 60px;

    }

    a {
        text-decoration: none;
        color: white;
    }

    .logo-right {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 8px;
        padding-top: 12px;
        margin-left: -7px;
        font-family: RoundBamboo;
        line-height: 13px;
        color: #fff;
        opacity: 0;
        transition: .5s;

        span {
            // color: var(--green-bg);
            font-family: MontBamboo;
            text-transform: lowercase;
            font-size: 12px;
        }
    }

    .active {
        // color: var(--green-bg);
        border-bottom: 2px solid #fff;
    }
}

.header-scrolled,
.mobile-header-scrolled {
    background-color: var(--dark-bg);
}

.chat-window-parent {
    position: fixed;
    top: 0;
    background-color: #000000a3;
    height: 100%;
    width: 100%;
    z-index: 2000000;

    .chat-window {
        position: absolute;
        right: 20px;
        bottom: 0;
        height: 500px;
        width: 400px;
        background-color: var(--dark-bg);
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .close-but {
            position: absolute;
            top: -20px;
            right: 10px;
            background-color: var(--dark-bg);
            font-size: 20px;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            cursor: pointer;

            div {
                height: 30px;
                width: 30px;
                background-color: var(--green-bg);
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .5s;
            }

            div:hover {
                background-color: #197903;
            }
        }

        .start-screen {
            padding-top: 30px;

            svg {
                color: var(--green-bg);
            }
        }

        .illustration {
            height: 120px;
            width: 170px;
            background-image: url(../assets/illustrations/messaging.svg);
            background-size: cover;
            background-position: center top;
        }

        .row {
            display: flex;
            column-gap: 10px;
            padding: 0 20px;
            justify-content: center;
            align-items: center;
        }

        h1 {
            margin: 0;
            padding: 0;
            text-align: center;
        }

        .but-parent {
            display: flex;
            padding: 0 20px;
            column-gap: 10px;
        }
    }
}

.modal {
    height: 100vh;
    width: 100vw;
    background-color: #000000c1;
    position: fixed;
    z-index: 300000;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
        border: 0;
    }
}

.top {
    display: flex;
    height: 100vh;
    position: relative;
    // padding: 0;

    .left {
        flex: 1.08;
    }

    .right {
        flex: 4;

    }

    .bg {
        background-image: url(../assets/galery/design2.jpg);
        background-size: cover;
        background-position: top center;
        width: 100%;
        height: 100%;
    }

    .title {
        position: absolute;
        top: 20vh;
        left: var(--padding-left);
        background-size: cover;
        background-image: url(../assets/logo/words.svg);
        height: 370px;
        width: 650px;
        background-position: right;
        // background-color: #ddd;

        // h1 {
        //     font-family: RoundBamboo;
        //     -webkit-text-stroke-width: 2px;
        //     -webkit-text-stroke-color: var(--dark-bg);
        //     font-size: 80px;
        //     line-height: 20px;
        // }

        // .foot {
        //     display: flex;

        //     h3 {
        //         text-transform: uppercase;
        //         border-right: 2px solid #fff;
        //         padding-right: 10px;
        //         margin-right: 20px;
        //         font-size: 30px;
        //     }
        // }

    }

    .play-bt {
        height: 140px;
        width: 140px;
        background-color: #1b1b1b6d;
        border: 6px solid #fff; //var(--green-bg);
        position: absolute;
        top: calc(50% - 83px);
        left: calc(59%);
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 50px;
        color: #fff;
        cursor: pointer;
        transition: .5s;
        opacity: .9;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    .play-bt:hover {
        background-color: var(--dark-bg);
        border-color: var(--green-bg);
        opacity: 1;
    }

    .square-button-parent {
        position: absolute;
        width: 80vw;
        background-color: var(--dark-bg);
        bottom: 0;
        left: calc(50% - 40vw);
        display: flex;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

        .square-button {
            flex: 1;
            height: 80px;
            border-right: 1px solid #3f3f3f;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 11px;
            text-transform: capitalize;
            letter-spacing: 1px;
            font-weight: 500;
            text-decoration: none;
            color: white;
            // font-size: 13px;
        }

        .rooms-square {
            position: relative;
        }

        // .square-button{
        //     border-top: 5px solid transparent;
        // }
        // .square-button:hover{
        //     border-top: 5px solid #00c8ff;
        // }

        .square-button:first-child {
            background-color: #fff;
            color: #3f3f3f;
            font-weight: 700;
            text-transform: uppercase;
        }

        .square-button:last-child {
            background-color: var(--green-bg);
            font-weight: 600;
            text-transform: uppercase;
            transition: .5s;
        }

        .square-button:last-child:hover {
            background: #197903;
        }

        .picker {
            width: 160px;
            position: relative;
        }

        .field {
            color: var(--green-bg);
            width: 100%;
            position: relative;
            text-transform: uppercase;
            margin-top: 5px;
            font-weight: 700;
            font-size: 13px;
        }

        .ic {
            position: absolute;
            right: 0px;
            font-size: 11px;
            top: 4px;
            color: #fff;
        }

        .range-parent {
            position: absolute;
            top: -373px;
            left: -35px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        }

        .calendar-parent {
            z-index: 50000;
            position: absolute;
            top: -325px;
            left: -35px;
            // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        }

        .room-selector {
            height: 0px;
            // height:0;
            // opacity: 0;
            width: 100%;
            background-color: var(--dark-bg);
            position: absolute;
            bottom: -220px;
            transition: .8s;
            overflow: hidden;

            .rs-title {
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 1px;
                margin: 20px;
                margin-bottom: 30px;
                position: relative;
            }

            .rs-row {
                display: flex;
                margin: 0 20px;
                margin-bottom: 20px;
            }

            .rs-type {
                flex: 1
            }

            .rs-ranger {
                display: flex;
                column-gap: 10px;
                justify-content: center;
                align-items: center;
            }

            .rs-button {
                background-color: var(--green-bg);
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
            }

            .rs-value {
                width: 15px;
                text-align: center;
            }
        }

        .picker-hidden {
            opacity: 0;
        }

        .room-selector-showing {
            height: 220px;
            // opacity: 1;
            bottom: -80px;
        }
    }

    .scroll-button {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        position: absolute;
        bottom: 70px;
        left: 0;
        transform: rotate(270deg);
        column-gap: 10px;
        line-height: 10px;
        cursor: pointer;

        .ic {
            font-size: 20px;
            letter-spacing: 0px;
        }
    }

    .social-buttons {
        list-style: none;
        font-size: 30px;
        position: absolute;
        bottom: -20px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        right: var(--padding-left);
        cursor: pointer;

        a {
            color: white;
        }
    }

    .chat-bubble {
        position: absolute;
        color: var(--green-bg);
        top: -70px;
        right: -12px;
        cursor: pointer;

        .ic {
            position: absolute;
            right: 0;
            height: 50px;
            width: 50px;
            background-color: #fff;
            border-radius: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            // z-index: 2000;
        }

        .talk-to-us {
            position: absolute;
            font-size: 15px;
            color: white;
            background-color: var(--green-bg);
            height: 50px;
            width: 0px;
            right: 20px;
            top: 0;
            border-radius: 40px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;
            padding-left: 20px;
            // font-family: Montbamboo;
            font-weight: 700;
            // justify-content: center;
            align-items: center;
            // z-index: 1000;
            overflow: hidden;
            transition: .5s;

            span {
                opacity: 0;
                transition: .5s;
            }
        }

        .scroll-show {
            width: 120px;

            span {
                opacity: 1;
            }
        }
    }
}

.room-parent {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow-x: scroll;

    .rooms {
        position: absolute;
        height: 100vh;
        display: flex;
        top: 0;
        left: 0;
        transition: .5s;

        .room {
            display: flex;
            position: relative;
            min-width: 100vw;
        }

        .left,
        .right {
            padding-top: 70px;
        }

        .right {
            flex: 1.8;
            display: flex;
            justify-content: center;
            align-items: center;

            .room-carousel {
                width: 750px;
            }

            .room-item {
                height: 100%;
                min-height: 500px;
                width: 100%;
                background-color: #ddd;
                background-size: cover;
                background-position: center;
                border-radius: 8px;
            }

            .room-thumb-item {
                background-color: #ffffff;
                min-height: 3px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            }

            .image-gallery-thumbnails-wrapper {
                top: -30px;
                margin-right: 20px;
                margin-left: 20px;
            }
        }

        .left {
            padding-left: 80px;
            padding-top: 110px;
            flex: 1;

            h1 {
                font-family: RoundBamboo;
                font-size: 60px;
                line-height: 70px;
                margin-bottom: 20px;
            }

            p {
                font-size: 15px;
                padding-right: 20px;
                margin-bottom: 30px;
                text-align: justify;
                font-weight: 500;
            }

            .price {
                font-weight: bold;
                color: var(--green-bg);

                span {
                    // font-weight: 500;
                    color: white;
                }
            }
        }

        .graphic {
            // background-color: antiquewhite;
            // height: 50vh;
            // width: 200px;
            position: relative;
            margin-top: 40px;

            .f1 {
                position: absolute;
                left: 0;
                top: 20px;
                width: 400px;
                height: 510px;
                background-color: var(--green-bg);
                border-top-left-radius: 160px;
                border-bottom-right-radius: 160px;
                background-size: cover;
            }

            .f2 {
                position: absolute;
                left: 420px;
                top: 20px;
                width: 150px;
                height: 150px;
                background-color: var(--green-bg);
                border-radius: 100px;
                background-size: cover;
            }

            .f3 {
                position: absolute;
                left: 420px;
                top: 190px;
                width: 150px;
                height: 150px;
                background-color: var(--green-bg);
                border-radius: 100px;
                background-size: cover;
            }

            .f4 {
                position: absolute;
                left: 420px;
                top: 360px;
                width: 150px;
                height: 150px;
                background-color: var(--green-bg);
                border-radius: 100px;
                background-size: cover;
            }
        }

        .amenities {
            // display: flex;
            column-gap: 20px;
            padding-right: 30px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            div {
                // border: 1px solid #fff;
                // background-color: #444444;
                // font-size: 25px;
                flex: 1;
                height: 50px;
                // padding: 30px 20px;
                border-radius: 20px;
                display: flex;
                // flex-direction: column;
                // justify-content: center;
                align-items: center;
                column-gap: 10px;
                color: var(--green-bg);
                min-width: 100px;
            }

            span {
                font-size: 10px;
                text-transform: uppercase;
                font-family: MontBamboo;
                letter-spacing: 2px;
                // font-weight: 700;
                color: white;
            }
        }

    }



    .arrow-left,
    .arrow-right {
        font-size: 40px;
        position: absolute;
        top: calc(50% - 20px);
        background-color: var(--green-bg);
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
    }

    .arrow-left {
        left: 30px;
        display: none;
    }

    .arrow-right {
        right: 30px;
    }

    .selector-dots {
        position: absolute;
        bottom: 35px;
        width: 100%;
        // background-color: rgb(18, 167, 226);
        display: flex;
        justify-content: center;
        column-gap: 10px;
    }
}

.rooms-scrolled .rooms {
    left: -100vw !important;
}

.rooms-scrolled .arrow-right {
    display: none;
}

.rooms-scrolled .arrow-left {
    display: flex;
}

.location {
    height: 100vh;
    display: flex;
    position: relative;

    .left,
    .right {
        padding-top: 120px;
    }

    .left {
        flex: 2;
        padding: 0;
        // padding-left: 80px;
    }

    .right {
        flex: 0;
        padding-left: 70px;
        padding-right: var(--padding-left);
    }

    .map {
        height: 100%;
        width: 100%;
        background-color: rgb(54, 52, 52);
        // border-radius: 5px;
        overflow: hidden;
    }

    .marker {
        background-image: url(../assets/map/marker2.svg);
        height: 40px;
        width: 40px;
        background-size: cover;
    }

    h1 {
        font-family: RoundBamboo;
        font-size: 40px;
        line-height: 90px;
        margin-bottom: 10px;
    }

    p {
        font-size: 15px;
        margin-bottom: 30px;
        display: flex;
        // justify-content: center;
        align-items: center;
    }

    .but {
        width: 200px;
        margin-top: 70px;
    }

    .instructions {
        text-align: justify;
        padding-right: 50px;
    }
}

.activities-parent {
    // display: flex;
    height: 100vh;
    position: relative;

    h1 {
        font-family: RoundBamboo;
        text-align: center;
        font-size: 50px;
        line-height: 30px;
        padding-top: 150px;
    }

    .carousel {
        display: flex;
        column-gap: 10px;
        overflow: scroll;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    .carousel::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    .item {
        // background-color: antiquewhite;
        min-width: 300px;
        height: 450px;
    }

    .image {
        width: 100%;
        height: 300px;
        background-color: aquamarine;
        background-size: cover;
        background-position: center;
    }

    .title {
        text-align: center;
        font-size: 20px;
        font-family: MontBamboo;
        text-transform: capitalize;
        margin-top: 10px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .description {
        text-align: center;
        padding: 0 40px;
        font-size: 14px;
    }

    p {
        text-align: center;
        margin-bottom: 40px;
    }

    .view-more {
        margin-top: 10px;
        text-align: center;
        // font-weight: 700;
        color: var(--green-bg);
        font-size: 14px;
    }

}

.gallery-parent {
    min-height: 100vh;

    // 
    .image-gallery {
        padding: 150px;
        padding-top: 100px;

    }

    .carousel-item {
        background: rgb(36, 36, 36);
        background-size: cover;
        // background-position: center;
        // background-attachment: fixed;
        height: 70vh;
        width: 100%;
        margin-bottom: 10px;

        .glass {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(13.3px);
            -webkit-backdrop-filter: blur(13.3px);
        }

        img {
            height: 96%;
            display: block;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        }
    }

    .thumb-item {
        min-height: 70px;
        min-width: 70px;
        background: rgb(36, 36, 36);
        background-size: cover;
    }

    //     .fixed {
    //         background: Gainsboro;
    //         background-size: cover;
    //         background-position: center;
    //         background-image: url(../assets/galery/all2.jpeg);
    //         background-attachment: fixed;
    //         height: 200px;
    //         width: 100%;

    //     }
    // .fixed-in{
    //     height: 100%;
    //     width: 100%;
    //     background-image:linear-gradient(to bottom,#302f2fb6,#44424266);
    // }
    #block-contain {
        display: flex;
        flex-flow: column wrap;
        max-width: 100%;
        // width: 1100px;
        padding-top: 130px;
    }

    .block {
        padding: 5px;
        flex: 1 0 auto;
        overflow: hidden;
    }

    .inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        align-items: center;
        font-size: 3em;
        min-height: 100px;
        /* border: 1px solid black; */
        background: Gainsboro;
        background-size: cover;
        background-position: center;
    }




}

.contact-parent {
    height: 100vh;
    display: flex;
    position: relative;

    .left-side {
        padding-top: 60px;

        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;

        .map-still {
            height: 550px;
            width: 700px;
            background-image: url(../assets/map/map.png);
            background-size: cover;
            background-position: center;
            border-radius: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .icon {
                height: 70px;
                width: 70px;
                border-radius: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                // background-image: url(../assets/map/directions.png);
                color: white;
                background-color: var(--green-bg);
                background-size: cover;
                background-position: center;
                margin: 30px;
                font-size: 50px;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

                svg path {
                    stroke: white;
                }
            }
        }
    }

    .right-side {
        padding-top: 120px;
        padding-right: 50px;
        width: 500px;
        color: #e1e1e1;

        .row {
            display: flex;
            padding-right: 30px;
            column-gap: 20px;
        }

        p {
            font-weight: 500;
            color: #919191;
            width: 95%;
        }
    }
}


/* MOBILE */
.mobile-header {
    position: fixed;
    top: 0;
    width: 100vw;
    // padding: 15px;
    display: flex;
    z-index: 1000;
    transition: 0.5s;

    // justify-content: right;
    // padding-right: 30px;
    .logo {
        height: 50px;
        width: 50px;
        background-image: url(../assets/logo/white-origingal.svg);
        background-size: cover;
        margin: 15px;
    }

    .flex-out {
        flex: 1
    }

    svg {
        margin: 15px;
    }
}

.mobile-square-button-parent {
    position: absolute;
    top: calc(20vh + 210px);
    left: 20px;
    width: calc(100vw - 40px);
    background-color: var(--dark-bg);

    .row {
        display: flex;
        margin: 10px;
        column-gap: 10px;
    }

    .picker {
        padding: 10px;
        // display: flex;
        background-color: #3f3f3f;
        flex: 1;
        border-radius: 5px;
        font-size: 12px;

        svg {
            display: none;
        }

        .field {
            color: var(--green-bg);
            font-weight: 700;
            font-size: 14px;
        }
    }

    a {
        flex: 1;
        text-decoration: none;
    }

    .component {
        width: 100%;
    }

    .large-but {
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
    }

    .range-parent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgb(0 0 0 / 77%);
        z-index: 10000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .calendar-parent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgb(0 0 0 / 77%);
        z-index: 10000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .room-selector-parent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgb(0 0 0 / 77%);
        z-index: 10000000;
        display: flex;
        justify-content: center;
        align-items: center;

        .room-selector {
            background-color: var(--dark-bg);
            width: 80vw;
            padding: 20px;
            padding-top: 30px;
            padding-bottom: 30px;
            position: relative;

            .rs-row {
                display: flex;
                margin-top: 10px;
            }

            .rs-type {
                flex: 1
            }

            .rs-ranger {
                display: flex;
                column-gap: 10px;
            }

            .rs-button {
                background-color: var(--green-bg);
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
            }

            .close-bt {
                position: absolute;
                top: -15px;
                right: 10px;
                height: 40px;
                width: 40px;
                border-radius: 100px;
                background-color: #045313;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

}

@media only screen and (max-width : 992px) {
    .mobile-only {
        display: unset !important;
    }

    .mobile-hidden {
        display: none;
    }

    .header,
    .play-bt,
    .square-button-parent,
    .scroll-button,
    .arrow-left,
    .arrow-right,
    .social-buttons {
        display: none !important;
    }

    .landing-parent {
        width: 100vw;
        overflow-x: hidden;
        scroll-snap-type: none;
        scroll-snap-points-y: none;
        scroll-snap-type: none;
    }

    .top .bg {
        background-position: left;
        // background-image: url(../assets/bckgrounds/bonfire.jpg);
    }

    .top .left {
        flex: 0;
    }

    .top .title {
        left: calc(50% - 150px);
        height: 202px;
        width: 350px;
        // filter: drop-shadow(3px 0 0 var(--dark-bg)) drop-shadow(0 3px 0 var(--dark-bg)) drop-shadow(-3px 0 0 var(--dark-bg)) drop-shadow(0 -3px 0 var(--dark-bg));

    }

    .room-parent {
        height: 230vh;
    }

    .room-parent .rooms {
        height: unset;
        display: block;
    }

    .room-parent .rooms .right .room-item {
        min-height: 230px;
    }

    .room-parent .rooms .room {
        flex-direction: column;

        .right,
        .left {
            padding: 20px;
            padding-top: 30px;

            h1 {
                font-size: 35px;
                line-height: 45px;
                margin-top: 50px;
            }

            p {
                width: 90vw;
            }
        }

        .right {
            padding: 0;
            width: 100vw;
        }

        .right .room-carousel {
            width: 90vw;
            height: 300px;
        }
    }

    .activities-parent {
        height: unset;
        padding: 10px;
        // margin-top: 400px;
        margin-top: 3px;
    }

    .activities-parent .carousel {
        display: block;
    }

    .gallery-parent {
        display: none;
    }

    .contact-parent {
        display: block;
    }

    .contact-parent .left-side {
        padding: 10px;
    }

    .contact-parent .left-side .map-still {
        height: 300px;
        width: 82vw;
    }

    .contact-parent .right-side {
        width: 100%;
        padding: 10px;
    }

    .contact-parent .right-side .row {
        display: block;
        padding: 0 20px;
    }

    .component {
        flex: 1 1;
        width: 95%;
        display: flex;
    }

    .contact-parent .right-side {
        p {
            font-weight: 500;
            color: #919191;
            width: 86%;
            margin: auto;
        }

        p,
        h1 {
            text-align: center;
        }
    }
}