@font-face {
  font-family: 'RoundBamboo';
  src: url('./assets/fonts/Round-Bamboo.otf') format('truetype');
}

@font-face {
  font-family: 'Popbamboo';
  src: url('./assets/fonts/Pop-Bamboo.ttf') format('truetype');
}

@font-face {
  font-family: 'Montbamboo';
  src: url('./assets/fonts/Mont-Bamboo.ttf') format('truetype');
}
:root{
  --dark-bg:#1b1b1b;
  --dark-light-bg:#212121;
  --green-bg:#0ca12c;
  --padding-left:60px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  font-family: Montbamboo;
  box-sizing: border-box;
}

.noOpacity {
  opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffffc5;
    transition: background-color 5000s ease-in-out 0s;
    background: rgba(255, 255, 255, 0);
    /* backdrop-filter: blur(9.9px); */
    width: unset !important;
    /* // box-shadow: inset 0 0 20px 20px #23232329; */
}